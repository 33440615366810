.pricingcard {

    width: 400px;
    height: 670px;
    /* text-align: center; */


}

.cardtopheading {

    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #707070;
    opacity: 100%;

    font-family: 'Lato', 'Regular';


}


.cardpricingrupe {
    text-align: center;

    font-size: 80px;
    font-weight: 400;
    color: #0F89FE;
    opacity: 100%;
    /* padding: 20px; */
    padding: 10%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}

.rupesybol {
    text-align: center;

    /* padding-top: 10%;
    margin-top: 40%;
    padding-top: 30px; */

    font-size: 50px;
    font-weight: 400;
    color: #0F89FE;
    opacity: 100%;
    /* padding: 5px; */
    padding: 3%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}

.cardpricingparagraph {
    text-align: center;


    font-size: 15px;
    font-weight: 400;

    font-family: 'Lato', 'Regular';



    color: #707070;
    opacity: 100%;



}

.cardlist {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lato', 'Regular';
    color: #707070;
    opacity: 100%;
}

.symbolbox {
    border-radius: 1px;

    width: 6px;
    height: 7px;
    background-color: #1C6BFE;
    opacity: 100%;

    transform: skew(135deg);
}

.listalign {
    padding-left: 20px;
    margin-left: 70px;
    padding: 2px;


}

.pricingline {
    background-color: #D8E6FF;
    width: 300px;
    text-align: center;
    margin: auto;
    padding: auto;
    opacity: 50%;
    height: 2px;


}

.buynow:hover {
    background-color: #1C6BFE;
    color: #FFFFFF;
    opacity: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    width: 120px;
    height: 40px;
    border-radius: 5px;



}


.btn {
    border: 1px solid #707070;
    background-color: #FFFFFF;
    color: #1C6BFE;
    padding: 6px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.default:hover {
    background: #1C6BFE;
    color: #FFFFFF;
}

.pricingbox {
    width: 150px;
    height: 40px;
    background-color: #D8E6FF;
    opacity: 100%;
    align-items: center;
    text-align: center;
    margin: auto;
    padding: auto;
    color: #333333;
    font-size: 25px;
    font-weight: bold;
    margin-left: 50%;
    border-radius: 4px;
    font-family: 'Lato', 'Bold';

}

.boxInternational {

    width: 160px;
    height: 40px;
    background-color: #D8E6FF;
    opacity: 100%;
    align-items: center;
    text-align: center;
    margin: auto;
    padding: auto;
    color: #333333;
    font-size: 25px;
    font-weight: bold;
    margin-right: 50%;
    border-radius: 4px;
    font-family: 'Lato', 'Bold';
}


.pricingsymboll {

    position: absolute;
    z-index: 1;
    /* padding-left: -5%; */
    /* padding-top: -10%; */


    margin-top: -3%;
    margin-left: -5%;

}

.india-pricingcardsymboll {

    position: absolute;
    margin-left: 63%;
    margin-top: -33px;



}

.international-pricingcardsymboll {



    position: absolute;
    margin-top: -38px;
    margin-left: 91%;

}


/* ###################### laptop responsive */


@media screen and (max-width: 1025px) {
    .boxInternational {

        width: 160px;
        height: 40px;
        background-color: #D8E6FF;
        opacity: 100%;
        align-items: center;
        text-align: center;
        margin: auto;
        padding: auto;
        color: #333333;
        font-size: 25px;
        font-weight: bold;
        margin-right: 40%;
        border-radius: 4px;
    }

}





@media screen and (max-width: 1025px) {

    .pricingbox {
        width: 150px;
        height: 40px;
        background-color: #D8E6FF;
        opacity: 100%;
        align-items: center;
        text-align: center;
        margin: auto;
        padding: auto;
        color: #333333;
        font-size: 25px;
        font-weight: bold;
        margin-left: 40%;
        border-radius: 4px;

    }
}

/* ####################### mobile view */


@media screen and (max-width: 425px) {
    .boxInternational {

        width: 160px;
        height: 40px;
        background-color: #D8E6FF;
        opacity: 100%;
        align-items: center;
        text-align: center;
        margin: auto;
        padding: auto;
        color: #333333;
        font-size: 25px;
        font-weight: bold;
        margin-right: 30%;
        border-radius: 4px;
    }

}





@media screen and (max-width: 425px) {

    .pricingbox {
        width: 150px;
        height: 40px;
        background-color: #D8E6FF;
        opacity: 100%;
        align-items: center;
        text-align: center;
        margin: auto;
        padding: auto;
        color: #333333;
        font-size: 25px;
        font-weight: bold;
        margin-left: 30%;
        border-radius: 4px;

    }
}