.attandanceheading {
    text-align: left;
    font-size: 45px;
    font-weight: bolder;
    font-family: 'Lato', 'Black';
    line-height: normal;
    color: #333333;
    opacity: 100%;
}


@media only screen and (min-width: 160px) and (max-width: 600px) {
    .attandanceheading {
        font-size: 20px;
    }
}




.attandanceheadingright {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Lato', 'Bold';
    margin-top: -25px;
    color: #707070;
    opacity: 100%;
    padding: 20px;
}

@media only screen and (min-width: 160px) and (max-width: 600px) {
    .attandanceheadingright {
        font-size: 20px;
    }
}




.attandancetagline {
    padding: 20px;
    padding-left: 80px;
    font-size: 20px;
    font-weight: 400;
    color: #707070;
    text-align: left;
    font-family: 'Lato', 'Regular';
}


/* 
@media only screen and (min-width: 100px) and (max-width: 600px) {

    .attandancetagline {
        font-size: 10px;
    }
} */





.attandancesymbol {
    position: absolute;
    padding-top: 30px;




}