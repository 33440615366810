  /* Media Query for Mobile Devices */
  .container-img {
      position: relative;
      /* position: absolute; */
      text-align: center;
      font-family: 'Lato';
      font-weight: bold;
      /* color: white; */
  }

  @media (max-width: 480px) {
      .demo-body {
          /* background-color: red; */
      }


      .centere-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 20px;
      }

      .fist-line {
          font-size: 20px;
          color: #1C6BFE;

      }

      .your-line {
          font-size: 10px;
          color: #1C6BFE;
          padding-left: 10px;


      }

      .second-line {
          font-size: 20px;
          color: #1C6BFE;

      }

      .third-line {

          color: #707070;
          font-size: 10px;
          font-family: "lato";
          font-weight: 300;
          padding-left: 30px;


      }





  }

  /* Media Query for low resolution  Tablets, Ipads */



  @media (min-width: 481px) and (max-width: 767px) {
      .demo-body {
          background-color: yellow;
      }









  }

  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px) {
      .demo-body {
          background-color: blue;
      }









  }

  /* Media Query for Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px) {
      .demo-body {
          background-color: green;
      }









  }

  /* Media Query for Large screens */
  @media (min-width: 1281px) {
      .demo-body {
          background-color: black;
      }











  }