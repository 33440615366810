.calculate-bg {

    margin: auto;
    padding: auto;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #E7EFFE;
    padding-top: 50px;

}


.discountbutton {


    background-color: #1C6BFE;
    position: absolute;
    /* padding-top: -15px; */
    color: #FFFFFF;
    font-size: 10px;
    margin-top: -15px;
    margin-left: 15px;
    border-radius: 5px;
    padding: 1px;

}


.discountbutton-opt {


    background-color: #E7F0FF;
    position: absolute;
    /* padding-top: -15px; */
    color: #1C6BFE;
    font-size: 10px;
    margin-top: -15px;
    margin-left: 15px;
    border-radius: 5px;
    padding: 1px;

}


.calculate-h1 {
    margin: auto;
    padding: auto;
    align-items: center;
    width: 400px;
    position: relative;
    color: #101010;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    padding-top: 2%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}

.calculate-p {

    color: #606062;
    text-align: center;
    font-size: 20px;
    padding: 5px;
    font-weight: 400;


    font-family: 'Lato', 'Regular';


}

.calculate-input {
    background-color: #E7EFFE;
    border: 2px solid #00ACFF;
    border-radius: 4px;
    color: #0f4ec2;
    width: 150px;
    height: 45px;
    text-align: center;
    margin: 20px;
    font-size: 20px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}



.calculate-input::placeholder {

    color: #1C6BFE;
}


.calculate-button {

    margin: auto;
    padding: auto;
    text-align: center;







}


.calculate-India:hover {
    background-color: #074ccc;
}

.calculate-India {
    background-color: #1C6BFE;
    /* Green */
    border: none;
    color: #FFFFFF;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-top-left-radius: 12px;
    width: 100px;
    height: 40px;
    font-weight: 500;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.calculate-International:hover {
    background-color: #d0ddf5;
}



.calculate-International {
    background-color: #E7F0FF;
    border-top-right-radius: 12px;
    font-weight: 500;

    /* Green */
    border: none;
    color: #1C6BFE;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}




.calculate-Monthly:hover {
    background-color: #074ccc;
}

.calculate-Monthly {
    background-color: #1C6BFE;
    /* Green */
    border: none;
    color: #FFFFFF;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
    border-top-left-radius: 12px;
    width: 100px;
    height: 30px;
    font-weight: 500;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.calculate-Yearly:hover {
    background-color: #d0ddf5;
}



.calculate-Yearly {
    background-color: #E7F0FF;
    border-top-right-radius: 12px;
    font-weight: 500;

    /* Green */
    border: none;
    color: #1C6BFE;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
    width: 100px;
    height: 30px;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.calculate-buynow {

    background-color: #E7F0FF;
    margin: auto;
    padding: auto;
    text-align: center;
    height: 400px;
    padding-top: 100px;






}

.calculate-rs {

    text-align: center;

    font-size: 35px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #1C6BFE;



}

.calculate-fee {

    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #000000;






}

.calculate-buttons {
    background-color: #1C6BFE;
    border: 2px solid #1C6BFE;
    border-radius: 4px;
    color: #FFFFFF;
    width: 100px;
    height: 40px;
    text-align: center;
    margin: 20px;
    font-size: 15px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.calculate-buttons:hover {


    background-color: #0b5df7;

}

.priceestimate {

    color: #606062;
    padding-top: 20px;
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.calculate-basicprice {

    text-align: left;
    /* padding-top: 30px; */
    padding-top: 35px;
    color: #606062;








}

.calculate-span {
    padding: 30px;
    text-align: left;


}

.calculate-onboardibg {

    padding-left: 65px;
    text-align: left;


}

.calculate-gst {


    text-align: left;
    padding-left: 305px;
}

.calculate-persentgst {
    padding-left: 70px;
    /* margin-left: 50px; */

}

.calculate-user {

    text-align: left;
    /* padding-left: 1px; */

}

.calculate-totalprice {
    padding-left: 280px;
}

.calculate-fontsize {

    color: #606062;
    font-size: 15px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}

.calculate-hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 20px;
    margin-right: 30px;
    border-style: inset;
    border-width: 1px;
    border-color: #00ACFF;
    opacity: 30%;
    /* width: 400px; */

}

.calculate-hr-top {
    display: block;
    /* margin-top: 20px; */
    margin-bottom: 0.5em;
    margin-left: 20px;
    margin-right: 650px;
    border-style: inset;
    border-width: 1px;
    border-color: #00ACFF;
    opacity: 30%;
    width: 10%;

}

.calculate-pricingcardsymboll {

    margin-left: 86%;
    /* margin-top: 10px; */



    /* padding-left: 820px; */
    /* padding-top: -40px; */
    margin-top: -40px;
    position: absolute;



}



table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    color: #606062;
    font-size: 18px;
    opacity: 100%;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


@media (max-width: 480px) {
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        color: #606062;
        font-size: 9px;
        opacity: 100%;
        font-weight: 600;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }


}

@media (min-width: 480px) {
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        color: #606062;
        font-size: 18px;
        opacity: 100%;
        font-weight: 600;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

}






th,
td {
    text-align: left;
    padding: 20px;
    color: #606062;
}



@media (max-width: 480px) {
    .usermonth {
        font-size: 9px;
        font-weight: 400;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

}

@media (min-width: 480px) {
    .usermonth {
        font-size: 17px;
        font-weight: 400;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
}



/* @@@@@@@@@@@@@@@@@@@@@@@@@@@ mobile res........... */

@media screen and (max-width: 425px) {

    th,
    td {
        text-align: left;
        color: #606062;
        padding: 1px;




    }
}