.hiring {

    font-size: 40px;
    padding-top: 20px;
    margin-left: 30px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


@media (max-width: 480px) {
    .hiringparagraph {
        font-size: 15px;
        text-align: left;
        padding-top: 20px;
        font-weight: 500;
        color: #707070;
        margin-left: 30px;
        font-family: 'Lato', 'Regular';
    }

}

@media (min-width: 480px) {
    .hiringparagraph {
        font-size: 20px;
        text-align: left;
        padding-top: 20px;
        font-weight: 500;
        color: #707070;
        margin-left: 30px;
        font-family: 'Lato', 'Regular';
    }
}





@media (min-width:600px) and (max-width:900px) {
    .hiringparagraph {
        font-size: 15px;
        text-align: left;
        padding-top: 20px;
        font-weight: 500;
        color: #707070;
        margin-left: 30px;
        font-family: 'Lato', 'Regular';
    }
}








.hiringboldtop {
    color: #333333;
    opacity: 100%;
    font-size: 52px;
    padding-top: 20px;
    margin-left: 30px;
    font-weight: bold;
    font-family: 'Lato', 'Black';
    opacity: 100%;
    line-height: normal;
}


@media only screen and (min-width: 100px) and (max-width: 600px) {
    .hiringboldtop {
        font-size: 25px;
    }
}



@media only screen and (min-width: 600px) and (max-width: 1100px) {
    .hiringboldtop {
        font-size: 30px;
    }
}









.Servicescard {
    background-color: #E7F0FF;
}


.symboll-img-recuriment {


    position: absolute;
    z-index: -1;
    margin-top: -3%;
    margin-left: -8%;



}



.symboll-img-recument {

    position: absolute;
    z-index: -1;
    margin-left: -10%;
    margin-top: -1%;



}

.symboll-img-recument-2 {

    position: absolute;
    z-index: -1;
    margin-left: -10%;
    margin-top: 20px;


}