.sectionblog {

    padding: 20px;
    padding-bottom: 30px;

    background-color: #E7EFFE;

}

.blogcardheading {
    color: #8C8C8C;
    opacity: 100%;

    text-align: center;
    padding: 10px;
    font-size: 30px;
    font-weight: 200;

    font-family: 'Raleway', 'Regular';



}

.paragraphblog {
    line-height: 1.6;
    padding: 30px;
    color: #8C8C8C;
    opacity: 100%;
    letter-spacing: 1px;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;




}

.subparagraphblog {
    /* line-height: 1.6; */
    font-family: 'Lato', sans-serif;
    color: #8C8C8C;
    opacity: 100%;
    text-align: left;

    /* font-family: 'Lato '; */

    font-size: 18px;



}

.blogdetailheading {


    color: #8C8C8C;
    opacity: 100%;
    text-align: left;
    font-size: 30px;
    font-weight: 600;


    font-family: 'Raleway', 'Bold';




}

.readmeimage {

    text-align: center;
    margin: auto;

    padding: auto;
    width: 80%;
    background-color: #E7F3FE;
    opacity: 100%;




}

.readmetop {
    text-align: center;
    margin: auto;
    padding: auto;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Raleway', 'Bold';


    color: #8C8C8C;
    opacity: 100%;
    line-height: 1.2;
    padding: 10px;
}

.readmetopparagraph {
    text-align: center;
    color: #8C8C8C;
    opacity: 100%;
    margin: auto;
    padding: auto;
    font-size: 15px;
    padding-bottom: 20px;
    font-weight: 400;
    font-family: 'Raleway', 'Regular';





}


.backbutton {

    text-align: center;
    font-size: 20px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #8C8C8C;
    position: relative;



}


.test2 {
    writing-mode: vertical-rl;
    position: fixed;
    padding-top: 200px;
    text-align: left;
    padding-left: 25px;
    font-size: 30px;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



}