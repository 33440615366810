@media (max-width: 600px) {
    .display-none-form {
        display: none;
    }
}

@media (min-width: 600px) {
    .display-show {
        display: none;
        margin: auto;
        text-align: center;
        padding: auto;
    }
}

@media (max-width: 480px) {
    .logohrverse {
        text-align: center;
        height: 25px;
        object-fit: contain;
        padding-left: 13%;
    }
}

@media (min-width: 480px) {
    .logohrverse {
        text-align: center;
        height: 45px;
        object-fit: contain;
    }
}

@media (min-width:600px) and (max-width:1000px) {
    .Typewriter {
        font-weight: 900;
        font-size: 20px;
        display: flex;
        justify-content: left;
        align-items: center;
        color: #000000CC;
    }
}






@media (max-width: 480px) {
    .Typewriter {
        font-weight: 900;
        font-size: 25px;
        display: flex;
        justify-content: left;
        align-items: center;
        color: #000000CC;
    }

}

@media (min-width: 480px) {
    .Typewriter {
        font-weight: 900;
        font-size: 25px;
        display: flex;
        justify-content: left;
        align-items: center;
        color: #000000CC;
    }
}























@media (max-width: 480px) {
    .normalfont {
        padding-top: 40px;
        color: #707070;
        font-weight: 500;
        font-family: 'Lato', "Regular";
        font-size: 15px;
    }


}

@media (min-width: 480px) {

    .normalfont {
        padding-top: 40px;
        color: #707070;
        font-weight: 500;
        font-family: 'Lato', "Regular";
        font-size: 20px;
    }

}

@media (min-width:600px) and (max-width:900px) {
    .normalfont {
        padding-top: 40px;
        color: #707070;
        font-weight: 500;
        font-family: 'Lato', "Regular";
        font-size: 15px;
    }
}





@media (max-width: 480px) {
    .textaligncenter {
        text-align: center;
        font-size: 25px;
        padding-top: 50px;
        color: #7B3F00;
        opacity: 1;
        font-family: 'Raleway', "SemiBold";
        font-weight: bolder;
    }

}

@media (min-width: 480px) {

    .textaligncenter {
        text-align: center;
        font-size: 60px;
        padding-top: 50px;
        color: #7B3F00;
        opacity: 1;
        font-family: 'Raleway', "SemiBold";
        font-weight: bolder;
    }
}


@media (min-width:600px) and (max-width:900px) {
    .textaligncenter {
        text-align: center;
        font-size: 40px;
        padding-top: 50px;
        color: #7B3F00;
        opacity: 1;
        font-family: 'Raleway', "SemiBold";
        font-weight: bolder;
    }

}






@media (max-width: 480px) {
    .textsizeparagraph {
        text-align: center;
        font-size: 15px;
        color: #1C6BFE;
        opacity: 1;
        /* padding: 10px; */
        font-weight: normal;
        font-family: 'Raleway', "Regular";
    }

}



@media (min-width: 480px) {

    .textsizeparagraph {
        text-align: center;
        font-size: 30px;
        color: #1C6BFE;
        opacity: 1;
        font-weight: normal;
        font-family: 'Raleway', "Regular";
    }
}



@media (min-width:600px) and (max-width:900px) {
    .textsizeparagraph {
        text-align: center;
        font-size: 20px;
        color: #1C6BFE;
        opacity: 1;
        font-weight: normal;
        font-family: 'Raleway', "Regular";
    }
}








.aligncenter {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-left: 30%;
    padding-top: 70px;



}

.Startup {
    font-size: 38px;
    color: #707070;
    font-weight: 400;
    font-family: 'Lato', 'Regular';



}


@media (max-width: 480px) {

    .toiler {
        text-align: center;
        font-size: 25px;
        padding-top: 50px;
        color: #707070;
        opacity: 1;
        font-family: 'Lato', 'Bold';
        font-weight: 600;
    }
}

@media (min-width: 480px) {

    .toiler {
        text-align: center;
        font-size: 50px;
        padding-top: 50px;
        color: #707070;
        opacity: 1;
        font-family: 'Lato', 'Bold';
        font-weight: 600;
    }
}


@media (min-width:600px) and (max-width:1100px) {
    .toiler {
        text-align: center;
        font-size: 35px;
        padding-top: 50px;
        color: #707070;
        opacity: 1;
        font-family: 'Lato', 'Bold';
        font-weight: 600;
    }
}














.spancolor {

    color: #1C6BFE;
}

/* .horizontalline {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 800px;
} */

.horizontalline2 {

    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 1000px;


}

.horizontallinebottom {

    display: block;
    margin-top: -3.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    width: 1000px;
    width: 100%;
    margin-top: 5px;
}

@media screen and (max-width: 600px) {

    .horizontallinebottom,
    .horizontalline2,
    .horizontalline {
        /* width: 100px; */
        display: none;
    }
}

@media screen and (max-width: 1000px) {

    .horizontallinebottom,
    .horizontalline2,
    .horizontalline {
        /* background-color: lightgreen; */
        width: 500px;
    }
}


@media screen and (max-width: 900px) {
    .Startforfreecontainer {
        /* background-color: #1c9ada; */
        /* background-color: #E7F3FE; */
        /* opacity: 100%; */
        /* border-radius: 10px; */

        padding-left: 25px;
        padding-right: 25px;
        margin-left: 25px;
        margin-right: 25px;
        z-index: -1;

    }



}

@media screen and (max-width: 1400px) {
    .Startforfreecontainer {
        /* background-color: #1c9ada; */
        /* background-color: #E7F3FE; */
        /* opacity: 100%; */
        /* border-radius: 10px; */
        padding-left: 25px;
        padding-right: 25px;
        margin-left: 25px;
        margin-right: 25px;

    }



}






@media screen and (max-width: 650px) {
    .aligncenter {
        align-items: left;
        text-align: left;
        justify-content: center;
        padding-left: 10%;
        padding-top: 50px;
    }

    .Startup {
        font-size: 20px;
    }
}