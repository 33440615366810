.startupimage {
    width: 100%;
    height: auto;
    object-fit: contain;
}



@media screen and (max-width: 600px) {
    .startupimage {
        width: 100%;
        height: 180px;
        object-fit: cover;
        /* background-color: red; */
    }
}

.containers {
    position: relative;
    text-align: center;
    color: white;
}

.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}

.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}

.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.centered-startup {
    position: absolute;
    top: 35%;
    left: 25%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 700;
    color: #7B3F00;
    opacity: 100%;
    text-transform: uppercase;
    font-family: 'Lato', 'Black';

}

.youfont-startup {
    position: absolute;
    top: 43%;
    /* left: 65%; */
    /* transform: translate(-50%, -50%); */
    font-size: 40px;
    font-weight: 600;
    color: #1C6BFE;
    opacity: 100%;
    text-transform: lowercase;
    font-family: 'Lato', 'Black';
}

.Growthfont-startup {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 700;
    color: #1C6BFE;
    opacity: 100%;
    text-transform: uppercase;
    font-family: 'Lato', 'Black';
}

.lowcasfont-startup {
    position: absolute;
    top: 70%;
    left: 30%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-weight: 400;
    color: #707070;
    opacity: 100%;
    /* text-transform: uppercase; */
    text-transform: capitalize;
    font-family: 'Lato', 'Regular';
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */

}

.Growthfont-startupButton {
    position: absolute;
    top: 77%;
    left: 60%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 700;
    /* color: #1C6BFE; */
    opacity: 100%;
    font-family: 'Lato', 'Black';
    text-transform: uppercase;

}






@media screen and (max-width: 900px) {
    .centered-startup {
        /* position: absolute; */
        top: 26%;
        left: 21%;
        /* transform: translate(-50%, -50%); */
        font-size: 35px;
        font-weight: 700;
        color: #7B3F00;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }
}


@media screen and (max-width: 900px) {
    .Growthfont-startupButton {
        position: absolute;
        top: 25%;
        left: 23%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        font-weight: 700;
        /* color: #1C6BFE; */
        opacity: 100%;
        text-transform: uppercase;

    }
}



@media screen and (max-width: 900px) {
    .Growthfont-startup {
        position: absolute;
        top: 37%;
        left: 25%;
        transform: translate(-50%, -50%);
        font-size: 35px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';

    }

}

@media screen and (max-width: 900px) {
    .lowcasfont-startup {
        position: absolute;
        top: 53%;
        left: 26%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Regular';

        /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */

    }





}

/* mobile resonsive############################ */


@media screen and (max-width: 650px) {
    .centered-startup {
        /* position: absolute; */
        top: 22%;
        padding-top: 1%;
        left: 25%;
        /* transform: translate(-50%, -50%); */
        font-size: 25px;
        font-weight: 700;
        color: #7B3F00;
        opacity: 100%;
        text-transform: uppercase;

    }
}



@media screen and (max-width: 390px) {
    .centered-startup {
        /* position: absolute; */
        top: 25%;
        padding-top: 1%;
        /* left: 25%; */
        /* transform: translate(-50%, -50%); */
        font-size: 16px;
        /* font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase; */
    }
}







@media screen and (max-width: 650px) {
    .Growthfont-startupButton {

        position: absolute;
        /* top: 27%; */
        padding-top: 15%;
        left: 25%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        font-weight: 700;
        /* color: #1C6BFE; */
        opacity: 100%;
        text-transform: uppercase;

    }
}



@media screen and (max-width: 650px) {
    .Growthfont-startup {
        position: absolute;
        top: 27%;
        padding-top: 2%;
        left: 25%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
    }

}



@media screen and (max-width: 390px) {
    .Growthfont-startup {
        position: absolute;
        top: 30%;
        padding-top: 2%;
        left: 25%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
    }
}









@media screen and (max-width: 650px) {
    .lowcasfont-startup {
        position: absolute;
        top: 40%;
        padding-top: 4%;
        left: 35%;
        transform: translate(-50%, -50%);
        font-size: 15px;

        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: capitalize;
        font-family: 'Lato', 'Regular';
        /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    }
}




@media screen and (max-width: 390px) {
    .lowcasfont-startup {
        position: absolute;
        top: 45%;
        padding-top: 4%;
        left: 35%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Regular';
        /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    }
}





@media screen and (max-width: 650px) {
    .youfont-startup {
        position: absolute;
        top: 1%;
        /* left: 65%; */
        /* transform: translate(-50%, -50%); */
        font-size: 25px;
        font-weight: 500;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
}



@media screen and (max-width: 390px) {
    .youfont-startup {
        position: absolute;
        top: 1%;
        font-size: 18px;
        font-weight: 500;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
}










@media screen and (max-width: 768px) {
    .youfont-startup {
        position: absolute;
        top: 30%;
        /* left: 65%; */
        /* transform: translate(-50%, -50%); */
        font-size: 20px;
        font-weight: 600;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Black';
    }
}


@media screen and (max-width: 390px) {
    .youfont-startup {
        position: absolute;
        top: 10%;
        font-size: 16px;
    }
}



@media (min-width:300px) and (max-width:600px) {
    .youfont-startup {
        position: absolute;
        top: 40%;
        font-size: 15px;
    }
}


/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%   TABLET RES.... */

/* 
@media screen and (max-width: 768px) {
    .centered-startup {
        top: 15%;
        left: 60%;
        font-size: 56px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;

    }

}

@media screen and (max-width: 768px) {
    .Growthfont-startup {
        position: absolute;
        top: 46%;
        left: 70%;
        transform: translate(-50%, -50%);
        font-size: 45px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;

    }
}




/*  */
@media screen and (max-width: 768px) {
    .Growthfont-startupButton {
        position: absolute;
        top: 80%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        font-weight: 700;
        opacity: 100%;
        text-transform: uppercase;
    }
}