@media (max-width: 480px) {
    .pricingheading {
        color: #333333;
        opacity: 100%;
        font-size: 30px;
        font-weight: 800;
        font-family: 'Fredoka One', Regular;
        text-align: center;
    }

}

@media (min-width: 480px) {
    .pricingheading {
        color: #333333;
        opacity: 100%;
        font-size: 55px;
        font-weight: 800;
        font-family: 'Fredoka One', Regular;
        text-align: center;
    }
}
@media (max-width: 480px) {
    .pricingheadingsecond {

        color: #333333;
        opacity: 100%;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        font-family: 'Lato', 'Bold';
    }
    
}

@media (min-width: 480px) {
    .pricingheadingsecond {

        color: #333333;
        opacity: 100%;
        font-size: 45px;
        font-weight: bold;
        text-align: center;
        font-family: 'Lato', 'Bold';
    }
    
}








@media (max-width: 480px) {
    .pricingparagraph {
        font-size: 15px;
        font-weight: 400;
        color: #606062;
        text-align: center;
        opacity: 100%;
        font-family: 'Raleway', 'Regular';
    }

}

@media (min-width: 480px) {
    .pricingparagraph {
        font-size: 20px;
        font-weight: 400;
        color: #606062;
        text-align: center;
        opacity: 100%;
        font-family: 'Raleway', 'Regular';
    }
}