/* .App {
    font-family: sans-serif;
    width: 30%;
    height: 20%;
    
    padding-left: 500px;
}

h1 {
    text-align: center;
} */

form .data {
    height: 30px;
    width: 100%;
    margin: 10px 0;
}

form .data label {
    font-size: 18px;
    padding-left: 10px;
}

from .data label:hover {
    color: green;
}

form .data input {
    height: 100%;
    width: 100%;
    padding-left: 10px;
    font-size: 17px;
    border: 1px solid silver;
}

form .data input:focus {
    border-color: #3498db;
    border-bottom-width: 2px;
}

form .data select {
    height: 100%;
    width: 100%;
    padding-left: 10px;
    font-size: 17px;
    border: 1px solid silver;
}

.button--style {
    height: 45px;
    width: 80px;
    margin-right: 30px;
}