.titelfont {
    color: #707070;
    opacity: 100%;
}



.parallelogram:hover {
    width: 6px;
    height: 5px;
    transform: skew(120deg);
    background: #1C6BFE;
}

.parallelogram {
    width: 6px;
    height: 5px;
    transform: skew(120deg);
    /* background: #474d58; */
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 100;
}

.paddingbox {
    padding-left: 70px;


}

/* @@@@@@@@@@@@@@@@@22222 */

.tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted rgb(202, 53, 53); */

}

.tooltip:hover {

    color: #1C6BFE;

}

.tooltip .tooltiptext {
    visibility: hidden;
    /* width: 1px;
    height: 1px; */

    /* width: 5px; */
    width: 2px;
    height: 1px;
    /* height: 2px; */
    transform: skew(120deg);
    background-color: #1C6BFE;
    /* background-color: #fe1c27; */



    /* transform: skew(150deg); */
    transform: skew(350deg);

    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.zoom:hover {
    -ms-transform: scale(1.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Safari 3-8 */
    transform: scale(1.1);
}














@media (max-width: 480px) {

    .cardcontentfirst {
        color: #707070;
        text-align: left;
        opacity: 100%;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
    }


}

@media (min-width: 480px) {

    .cardcontentfirst {
        color: #707070;
        text-align: left;
        opacity: 100%;
        font-size: 22px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
    }

}







@media (max-width: 480px) {
    .cardcontentsecond {
        margin-top: 10px;
        color: #707070;
        text-align: left;
        opacity: 100%;
        font-size: 10px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
    }


}

@media (min-width: 480px) {
    .cardcontentsecond {
        margin-top: 25px;
        color: #707070;
        text-align: left;
        opacity: 100%;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
    }

}

@media (min-width:600px) and (max-width:1100px) {
    .cardcontentfirst {
        color: #707070;
        text-align: left;
        opacity: 100%;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
    }
}

@media (min-width:600px) and (max-width:1100px) {
    .cardcontentsecond {
        margin-top: 10px;
        color: #707070;
        text-align: left;
        opacity: 100%;
        font-size: 10px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
    }
}