@media (max-width: 480px) {
    .empowering-style {
        font-size: 20px;
    }

    .startup-paragraph {
        font-size: 15px;
    }

}

@media (min-width: 480px) {
    .empowering-style {
        font-size: 40px;
    }

    .startup-paragraph {
        font-size: 20px;
    }
}



@media (min-width:600px) and (max-width:1200px) {
    .empowering-style {
        font-size: 30px;
    }
}