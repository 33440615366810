.formposition-signup {

    text-align: left;
    width: 25%;


}

.contactdetail {
    text-align: left;
    padding-top: 160px;


}

.callus {


    color: #333333;
    opacity: 100%;
    font-size: 25px;
    font-weight: 600;
    /* font-weight: bold; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.callus-paragraph {

    padding-top: 20px;
    color: #333333;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Lato', 'Regular';


}

.contactnumber {

    padding-top: 20px;
    color: #01AAFF;
    opacity: 100%;
    padding-left: 10px;
    font-size: 40px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



}

.signupsymboll {
    position: absolute;
    z-index: 1;
    height: auto;
    margin-left: -13%;
    margin-top: -10%;
    width: 25%;




}