.props-title {

    color: #333333;
    font-family: "Lato";
    font-weight: bold;
    font-size: 20px;
    text-align: center;


}

.card-style {
    /* min-width: 360px; */
    width: 330px;

}

.sub-feratures {
    color: #707070;
    font-family: "Lato", Regular;
    text-align: justify;
    padding: 10px;
    font-size: 15px;
}


.zoom-card:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.transaction-feature {
    color: #1C6BFEed;
    font-size: 25px;
    font-family: "Lato";
    font-weight: bold;


}

/* .main-card-feature:hover {
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.1);
} */


.main-card-feature {
    display: block;
    padding: 1%;
}

.one-pricing-for {


    color: #707070;
    font-family: "Lato", "Regular";
    padding: 20px;
    font-size: 20px;

}

.scroll-pricing {
    color: #0F89FE;
    font-size: 85px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.scroll-pricing-sym {

    color: #0F89FE;
    font-size: 40px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-right: 10%;

}

.per-emp {
    color: #707070;
    font-size: 15px;
    font-family: "Lato", Regular;
}


.scroll-features {
    color: #707070;
    font-family: "Lato";
    font-size: 18px;




}



.container-scroll-features {
    margin: auto;
}


*,
*::before,
*::after {
    margin: 0;
    padding: 0;
}

/* body {
    background: #333;
    color: #f2f2f2;
    min-height: 100%;
    min-width: 100%;
} */

.container-scrol {
    width: 90%;
    margin: auto;
}

.cards-container-features {
    display: flex;
    border-radius: 6px;
    /* color: #333; */
    /* background: #eee; */
    padding: 1rem;
    /* box-shadow: 0 0 1rem #000 inset; */
    overflow-x: auto;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}



.cards-container ::-webkit-scrollbar {
    display: none;


    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
}


.card-scroll {

    display: block;

    flex: 1 0 250px;
    box-shadow: 0 1px 1rem -4px #000;
    background: #fff;
    margin: 1rem;
    overflow: hidden;
    border-radius: 6px;
    cursor: pointer;
    transition: all 250ms ease-in-out;

    img {
        width: 250px;
        height: 250px;
        object-fit: center;
    }

    &:hover {
        box-shadow: 0 4px 1rem -4px #000;
        transform: translateY(-3px);
    }
}

.card-content {
    padding: 1rem;
}

@media screen and (max-width: 625px) {
    .container-scroll-features {
        width: 100%;
        margin: auto;
        padding-left: 0%;
    }

    .cards-container-features {
        padding: .5rem;
    }

    /* .card {
        margin: .5rem;
    } */
    .main-card-feature {
        display: block;
        padding: 1%;
    }

    .card-style {
        /* min-width: 250px; */
        width: 230px;
    }

    .transaction-feature {
        font-size: 15px;
    }

    .sub-feratures {

        font-size: 13px;
    }

    .zoom-card:hover {
        -ms-transform: scale(1);
        /* IE 9 */
        -webkit-transform: scale(1);
        /* Safari 3-8 */
        transform: scale(1);
    }

}