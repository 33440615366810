@media (min-width: 220px) and (max-width: 600px) {
    .centered-startup-responsive {
        position: absolute;
        top: 27%;
        left: 25%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';

    }

    .youfont-startup-Responsive {
        position: absolute;
        top: 43%;
        font-size: 10px;
        font-weight: 600;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Black';
    }


    .Growthfont-startup-responsive {
        position: absolute;
        top: 32%;
        left: 35%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .lowcasfont-startup-responsive {
        position: absolute;
        top: 45%;
        left: 30%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: capitalize;
        font-family: 'Lato', 'Regular';

    }

}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
    .demo-body {
        background-color: blue;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
    .demo-body {
        background-color: green;
    }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
    .demo-body {
        background-color: black;
    }
}