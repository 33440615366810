.Payrollfont {

    padding: 10px;
    margin: 50px;

}

.Servicesh1 {
    color: #333333;

    font-size: 65px;
    font-weight: bolder;
    font-family: 'Lato', 'Bold';


}

.Services-p {

    color: #333333;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Lato', 'Regular';



}

.symboll-img-sevices {

    margin-top: 1%;
    position: absolute;
    z-index: -1;
    margin-left: -14%;


}




@media screen and (max-width: 680px) {
    .symboll-img-sevices {

        margin-top: 1%;
        position: absolute;
        z-index: -1;
        margin-left: -30%;


    }

    .symboll-img-free {
        position: absolute;
        z-index: 1;
        margin-top: -2%;
        margin-left: -10%;
    }



}