@media (max-width: 480px) {
    .iconsize {
        width: 60px;
        height: 50px;
        margin: auto;
        padding: auto;
        text-align: center;
    }

}

@media (min-width: 480px) {

    .iconsize {
        width: 130px;
        height: 120px;
        margin: auto;
        padding: auto;
        text-align: center;
    }

}

@media (min-width:600px) and (max-width:1200px) {
    .iconsize {
        width: 100px;
        height: 95px;
        margin: auto;
        padding: auto;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .iconsizefont {
        color: #707070;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Lato', 'Bold';
    }
}

@media (min-width: 480px) {
    .iconsizefont {
        color: #707070;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Lato', 'Bold';
    }
}

@media (min-width:600px) and (max-width:1200px) {

    .iconsizefont {
        color: #707070;
        font-size: 25px;
        font-weight: bold;
        font-family: 'Lato', 'Bold';
    }
}

/* .radial-gradient {
    text-align: center;
    margin: auto;
    color: white;
    padding: auto;
    width: 30%;
    height: 5px;
    background:
        transparent radial-gradient(closest-side at 50% 50%, #0000009E 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
} */
/* .container:hover .overlay {
    opacity: 1;
} */


/* .iconsizefont:hover .radial-gradient {
    text-align: center;
    margin: auto;
    color: white;
    padding: auto;
    width: 30%;
    height: 5px;
    background:
        transparent radial-gradient(closest-side at 50% 50%, #0000009E 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
} */


/* .iconsize:hover {


    text-align: center;
    margin: auto;
    color: white;
    padding: auto;
    width: 30%;
    height: 5px;
    background:
        transparent radial-gradient(closest-side at 50% 50%, #0000009E 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
} */

/* .industryhover:hover {

    color: #691422;
    padding-bottom: 20px;


} */

.hovejump {
    text-align: center;
    padding-bottom: 40px;


}

.hovejump:hover {
    margin-top: -20px;
    /* background-color: #008CBA; */



}



.hide {
    display: none;
}

.myDIV:hover+.hide {
    display: block;
    /* color: red; */

    background: transparent radial-gradient(closest-side at 33% 88%, #0000009E 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box
}

/* .myDIV {
    padding-bottom: 40px;
} */


.hide {
    /* margin: 50px; */
    /* margin-left: 45%; */
    padding: auto;
    text-align: center;
    /* margin-top: -40px; */
}