.transactional {
    font-size: 25px;
    padding: 20px;
    text-align: center;
    padding: 10px;
    font-weight: 600;
    color: rgba(28, 107, 254, 1);
    opacity: 100%;
    font-family: 'Lato', 'Bold';



}

.cardparagraph {
    text-align: center;
    padding: 10px;
    padding-bottom: 10px;
    /* margin-bottom: 5px; */
    font-size: 15px;
    font-weight: 400;
    color: rgba(112, 112, 112, 1);
    opacity: 100%;
    text-align: center;

    font-family: 'Lato', 'Regular';




}


.cardlogo {
    padding-top: 30px;
    /* margin-top: 20px; */
    width: 100%;
    height: 100%;
    margin: auto;
    padding: auto;
    object-fit: contain;

    padding-bottom: 10%;
    margin-bottom: 10px;



}

.featuresfont {
    padding: 30px;
    font-size: 35px;
    color: hsla(0, 0%, 44%, 1);
    opacity: 100%;
    font-weight: 600;
    font-family: 'Lato', 'Bold';








}

.cardzoom:hover {
    -ms-transform: scale(0.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.festuresymboll {
    margin: auto;
    padding: auto;
    text-align: center;
    justify-content: center;


}