/* .parent {
    position: absolute;
    top: 0;
    left: 0;

}

.image1 {
    position: static;
    top: 0;
    left: 0;
    width: 50%;
    border: 1px solid #000000;
    
}

.image2 {
    position: static;
    top: -30px;
    left: 30px;
    width: 50%;
    border: 1px solid #000000;

    margin-top: -10px;
    margin-left: 20px;
   
} */

@media (max-width: 480px) {
    .applyfor-img {
        max-width: 100%;
        height: 10px;
        padding-left: 85%;
        object-fit: contain;



    }
}

@media (min-width: 480px) {
    .applyfor-img {
        max-width: 100%;
        height: 10px;
        padding-left: 95%;
        object-fit: contain;
    }
}






.startuphrad {
    font-size: 40px;
    margin-top: -20px;
}


@media only screen and (max-width: 425px) {
    .startuphrad {
        font-size: 20px;
    }
}



@media only screen and (max-width: 425px) {
    .startupparagraph {
        font-size: 15px;
        /* margin-left: -30px; */
    }
}



.startupparagraph {
    font-size: 16px;
    margin-top: -0px;
    font-family: 'lato';
    font-weight: 300;
    color: #606062;
}








.applyforcredit {

    background-color: #e7f3fe;
    /* height: 150vh; */
    height: auto;
    border-radius: 10px;
    position: relative;
    padding-bottom: 2%;
}


@media only screen and (max-width: 600px) {
    .applyforcredit {
        background-color: #e7f3fe;
        /* height: 150vh; */
        height: auto;
        border-radius: 10px;
    }
}

.mob-already {

    color: #606062;
    font-family: Lato;
    font-size: 18px;
    opacity: 100%;
    font-weight: 500;

}

/* .mob-start {
    color: #101010;
    font-family: 'Lato', 'Black';
    font-weight: bold;
    font-size: 40px;
} */


@media screen and (max-width: 425px) {
    .mob-resp {
        padding-top: 50px;

    }
}


@media screen and (max-width: 425px) {

    .mobile-container {
        width: 420px;
    }
}